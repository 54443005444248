import React from "react";
import "./Footer.scss";

export const Footer = () => (
  <footer className="footer">
    <div>
      <div>
        <a className="footerlabel">Built by Saad Farhan</a>
      </div>
    </div>
  </footer>
);
